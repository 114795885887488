// Import dependencies
import "phoenix_html";
import SmoothScroll from "smooth-scroll";
import { Socket } from "phoenix";
import { LiveSocket } from "phoenix_live_view";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

// Local files go under this line.

// Enable multiselect checkboxes
import { init_checkboxes } from "./association-checkboxes"

let Hooks = {}
Hooks.Checkboxes = {
  mounted() {
    init_checkboxes();
  }
}

// Init phoenix LiveView
let csrfToken = document.querySelector("meta[name='csrf-token']")
  .getAttribute("content");
let liveSocket = new LiveSocket("/live", Socket, { params: { _csrf_token: csrfToken }, hooks: Hooks });
liveSocket.connect();

// Initialize smooth scroll.
new SmoothScroll("a[data-scroll]");

// Load datetime pickers.
// These are those of bootstrap 3: https://getdatepicker.com/4/
// More explanation how to use in DsaWeb.Helpers.FormHelper.utc_datetime_local_input
document.configureDateTimePicker = function(selector, options) {
    $(selector).each(function() {
        $(this).data("DateTimePicker").options(options);
    });
}

document.initDateTimePicker = function(selector, options) {
    $(selector).datetimepicker(options);
}

$( document ).ready(function() {
    document.initDateTimePicker(".input-group.datetime:not(.manual)",{
        // This is a global variable, set by the template.
        // eslint-disable-next-line no-undef
        locale: appLanguage
      });
    document.initDateTimePicker(".input-group.date:not(.manual)",{
        // eslint-disable-next-line no-undef
        locale: appLanguage
    });
    document.initDateTimePicker(".input-group.time:not(.manual)", {
        // eslint-disable-next-line no-undef
        format:'LT',
        locale: appLanguage
    });

    $(document.body).on("click", "tr[data-href]", function () {
        window.location.href = this.dataset.href;
    })
});

// extra functions to use for datepickers
// possible events are: hide, show, change, error, update
document.dateTimePickerOn = function (selector, event, f){
    $(selector).on("change dp." + event, f)
}

// Enable elements to automatically submit.
let debounce;
$(".auto-submit")
  .change(function() {
    clearTimeout(debounce);
    const input = $(this);
    const form = input.closest("form");
    debounce = setTimeout(function() {
      if (input.val()) {
        input.prop("readonly", true);
        form.submit();
      }
    }, 700);
  });

// Use filetype check for specific forms

$(".check-filetype").change((e) => {
	if (!e.target.files) return;

	const allowed = e.target.getAttribute("accept").split(",");
	const files = [...e.target.files];
	const showError = files.some((file) => !allowed.includes(`.${file.name.split(".").pop()}`));
	if (showError) {
		// Show error
		e.target.parentElement.classList.add("has-error");
		e.target.parentElement.querySelector(".help-block.check-filetype-error").classList.remove("hidden");
	} else {
		// Hide error
		e.target.parentElement.classList.remove("has-error");
		e.target.parentElement.querySelector(".help-block.check-filetype-error").classList.add("hidden");
	}
});

