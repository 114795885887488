/*
    Adds event listeners to the 'categorical' checkboxes, e.g. All (Alle), All DSA konvents (Alle konventen onder DSA), etc.
    Toggles the right association checkboxes and other categorical checkboxes when needed.
    E.g. when the category 'All DSA associations' is checked, all DSA associations should be checked as well as the category checkbox 'All ensembles',
    because they are a subset of all the DSA associations.
  */
function toggleCategoricalCheckboxes(toggleId, containerId, checkboxes, conditions, condition) {
    document.getElementById(toggleId).addEventListener('change', function() {
        // Toggles the right association checkboxes
        checkboxes.forEach(checkbox => {
        if (condition(checkbox.id)) {
            checkbox.checked = this.checked;
        }
        });
        // Toggles the other categorical checkboxes when needed
        conditions.forEach(({ id }) => {
        if (id !== toggleId) {
            checkAssociationCheckboxes(id, checkboxes, conditions.find(c => c.id === id).condition);
        }
        });
    });
}
/*
Checks whether a categorical checkbox should be checked based on what associations are already selected.
*/
function checkAssociationCheckboxes(toggleId, checkboxes, condition) {
    const categoricalCheckbox = document.getElementById(toggleId);
    
    const allChecked = Array.from(checkboxes)
        .filter(checkbox => condition(checkbox.id))
        .every(checkbox => checkbox.checked);
    
    categoricalCheckbox.checked = allChecked;
}
/*
Adds event listeners to the association checkboxes.
*/
function addAssociationCheckboxListeners(toggleId, containerId, checkboxes, condition) {
    checkboxes.forEach(checkbox => {
        checkbox.addEventListener('change', function() {
        checkAssociationCheckboxes(toggleId, checkboxes, condition);
        });
    });
}

export function init_checkboxes() {
    const containerId = 'associations-div';
    const div = document.getElementById(containerId);
    const checkboxes = div.querySelectorAll('input[type="checkbox"]');
    const conditions = [
        { id: 'all', condition: () => true },
        { id: 'all-konvents', condition: id => id.includes('konvent') },
        { id: 'all-associations', condition: id => id.includes('vereniging') },
        { id: 'all-counsils', condition: id => id.includes('raad') },
        { id: 'all-ensembles', condition: id => id.includes('ensemble') }
    ];

    conditions.forEach(({ id, condition }) => {
        toggleCategoricalCheckboxes(id, containerId, checkboxes, conditions, condition);
        checkAssociationCheckboxes(id, checkboxes, condition);
        addAssociationCheckboxListeners(id, containerId, checkboxes, condition);
    });
}
